import ApiService from '@/common/api/api.service';

const apiBasePath = 'Conta';

const ContaService = {
    // FUNÇÕES CRUD:
    async criar(info) {
        const { data } = await ApiService.post(`${apiBasePath}`, info);
        return data;
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form);
        return data;
    },
    async excluir(itemId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${itemId}`);
        return data;
    },
    // FUNÇÕES FILTRO:
    async obterTodas(paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}`, {
            params: {
                TamanhoDaPagina: paginacao.tamanhoDaPagina,
                NumeroDaPagina: paginacao.numeroDaPagina
            }
        });
        return data;
    },

    async obterTodasSemPaginacao() {
        let { data } = await ApiService.get(`${apiBasePath}/sem-paginacao`);
        data.data = data.data.map(row => ({
            text: row.descricao,
            value: row.id
        }));
        return data;
    },
    async obterTodasSemPaginacaoOriginal() {
        let { data } = await ApiService.get(`${apiBasePath}/sem-paginacao`);
        return data;
    }
};

export default ContaService;
