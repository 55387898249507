<template>
  <div>
    <b-table
      :fields="tabela.fields"
      :items="tabela.items"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      show-empty
      responsive
      striped
      hover
    >
      <template #cell(acoes)="item">
        <div>
          <b-button size="sm" variant="primary" @click="selecionar(item.item)">
            {{ $t('GERAL.SELECIONAR') }}
          </b-button>
        </div>
      </template>
      <template #cell(bancoDescricao)="item">
        <div>
          {{ `${item.item.descricao} ` }}
        </div>
      </template>
    </b-table>

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getContas"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Service:
import ContaService from '@/common/services/conta/conta.service';

// Components:
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  components: { Paginacao },
  mounted() {
    this.getContas();
  },
  data() {
    return {
      tabela: {
        items: [],
        fields: [
          {
            key: 'acoes',
            label: this.$t('FINANCEIRO.ACOES'),
            class: 'w-160',
          },
          {
            key: 'bancoDescricao',
            label: this.$t('FINANCEIRO.BANCO'),
            sortable: true,
          },
        ],
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
    };
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodas(this.paginacao)
        .then(({ data }) => {
          this.tabela.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DE EMISSÃO DE DADOS:
    selecionar(item) {
      this.$emit('selecionado', item);
    },
  },
};
</script>
