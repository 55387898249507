<template>
  <topbar
    :titulo="$t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULOS')"
    :subtitulo="$t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULOS_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4 gap-1">
        <div></div>
        <b-button variant="secondary" @click="retornar">
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
        { titulo: this.$t('FINANCEIRO.LANCAMENTOS') },
        { titulo: this.$t('FINANCEIRO_CONTAS_RECEBER.PAGE_TITLE') },
        { titulo: this.$t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULOS') },
      ],
    };
  },
  methods: {
    retornar() {
      this.$router.push({ name: 'financeiro-contas-a-receber' });
    },
    cadastrar() {
      this.$emit('cadastrar', 'novoLancamento');
    },
  },
};
</script>
