<template>
  <div>
    <titulo-contas-receber-baixar-titulos />

    <div class="row mb-4">
      <div class="col-12 col-md-2">
        <input-date-only-btn
          ref="dataPagamento"
          v-model="form.dataPagamento"
          label="FINANCEIRO_CONTAS_RECEBER.DATA_PAGAMENTO"
        />
      </div>
      <div class="col-12 col-md-4">
        <input-select-search
          ref="tipoPagamento"
          v-model="form.tipoFormaPagamento"
          label="FINANCEIRO_CONTAS_RECEBER.TIPO_PAGAMENTO"
          :options="listaTipoFormaPagamento"
          required
        />
      </div>
      <b-col md="12" class="mt-auto">
        <selecionar-conta @selecionado="obterSelecionado" />
      </b-col>
    </div>

    <tabela-mostra-lista-titulo
      :form="titulos"
      :totalizadores="totalizadores"
      @retornaSelecao="retornaSelecao"
    />

    <b-row class="mt-3 float-right">
      <b-col>
        <b-button variant="primary" class="mr-3" @click="calcularJuros">
          {{ $t('FINANCEIRO_CONTAS_RECEBER.CALCULAR_JUROS') }}
        </b-button>
        <b-button variant="primary" @click="validarFormulario">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </b-col>
    </b-row>

    <modal-selecionar-desconto
      :exibir="modais.selecionarDesconto"
      :descontoSelecionado="form"
      :descontosFiltro="descontosFiltro"
      @selecionaDesconto="selecionaDesconto"
      @fechar="closeModal('selecionarDesconto')"
    />
  </div>
</template>

<script>
// Utils & Aux:
import verificacaoPreenchimento from '@/components/mixins/verificacao-preenchimento';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import breadcrumb from '@/common/utils/breadcrumb';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import modais from '@/common/utils/modais';
import { mapState } from 'vuex';

// Services & Models:
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import pdf from '@/common/utils/pdf';
import { BaixarTitulo } from '@/common/models';

// Components:
import SelecionarConta from '@/components/selecionar-entidades/modal/contas/Index';
import TabelaMostraListaTitulo from '@/views/financeiro/lancamentos/contas-receber/components/TabelaMostraListaTitulo.vue';
import ModalSelecionarDesconto from '@/views/financeiro/lancamentos/contas-receber/components/modais/selecionar-desconto/Index.vue';
import { InputSelectSearch, InputDateOnlyBtn } from '@/components/inputs';
import TituloContasReceberBaixarTitulos from '@/views/financeiro/lancamentos/contas-receber/baixar-titulos/components/TituloContasReceberBaixarTitulos';

export default {
  name: 'BaixarTitulo',
  mixins: [verificacaoPreenchimento],
  components: {
    TituloContasReceberBaixarTitulos,
    InputDateOnlyBtn,
    SelecionarConta,
    ModalSelecionarDesconto,
    TabelaMostraListaTitulo,
    InputSelectSearch,
  },
  data() {
    return {
      titleFormula: {
        title:
          'Cálculo automático de juros: <br><br> (Valor * M1 / 100) <br>+<br>  (Valor * J1 / 100 * D1); <br><br> <small>M1: Percentual de Multa <br> J1: Percentual de Juros ao dia <br> D1: Quantidade de Dias </small>',
      },
      form: new BaixarTitulo({}),
      idsSelecionados: [],
      titulos: [],
      listaTipoFormaPagamento: [],
      titulosSelecionados: [],
      ehLista: false,
      totalAux: 0,
      totalizadores: {
        somaTotalBaixas: 0,
        somaTotalValorDocBaixas: 0,
        somaTotalJurosBaixas: 0,
        somaTotalDescontoBaixas: 0,
      },
      descontos: [],
      descontosFiltro: [],
      idConta: 'modalContas',
      idDesconto: 'modalDesconto',
      ativo: false,
      modais: {
        selecionarConta: false,
        selecionarDesconto: false,
      },
    };
  },
  mounted() {
    if (this.baixartitulo.lancamentoSelecionados.length !== 0) {
      this.atribuir();
      this.buscarListaDeContasReceber();
      this.listarTipoFormaPagamento();

      breadcrumb.definir(this.$store, [
        { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
        { titulo: this.$t('FINANCEIRO.LANCAMENTOS') },
        { titulo: this.$t('FINANCEIRO_CONTAS_RECEBER.PAGE_TITLE') },
        { titulo: this.$t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULOS') },
      ]);

      return;
    }

    this.$router.push({ name: 'financeiro-contas-a-receber' });
  },
  watch: {
    descontos: 'descontosFiltradosParaTabela',
  },
  computed: {
    ...mapState(['baixartitulo']),
  },
  methods: {
    descontosFiltradosParaTabela(descontos) {
      this.descontosFiltro = descontos;
    },
    atribuir() {
      const { idsLancamento, lancamentoLista } =
        this.baixartitulo.lancamentoSelecionados;
      this.idsSelecionados = idsLancamento;
      this.ehLista = lancamentoLista;
    },
    calcularSomaTotalDeBaixas() {
      let valorTotal = 0;
      let valorTotalDocumento = 0;
      let valorTotalJuros = 0;
      let valorTotalDesconto = 0;

      this.titulos.forEach(({ valorDemonstrativo, valor, juros, desconto }) => {
        valorTotal += valorDemonstrativo;
        valorTotalDocumento += valor;
        valorTotalJuros += parseFloat(juros);
        valorTotalDesconto += desconto;
      });

      this.totalizadores.somaTotalValorDocBaixas =
        helpers.formatarReal(valorTotalDocumento);
      this.totalizadores.somaTotalBaixas = helpers.formatarReal(valorTotal);
      this.totalizadores.somaTotalJurosBaixas =
        helpers.formatarReal(valorTotalJuros);
      this.totalizadores.somaTotalDescontoBaixas =
        helpers.formatarReal(valorTotalDesconto);
    },
    buscarListaDeContasReceber() {
      this.$store.dispatch(START_LOADING);

      ContaReceberService.buscarListaDeContasReceber(this.idsSelecionados)
        .then((data) => {
          const acresentandoValorDemonstrativo = data.map((item) => {
            this.calcularDesconto();

            return {
              banco: item.banco,
              contaId: item.contaId,
              dataLancamento: item.dataLancamento,
              dataPagamento: item.dataPagamento,
              dataVencimento: item.dataVencimento,
              desconto: item.desconto,
              descontos: item.descontos,
              codigoMatricula: item.codigoMatricula,
              statusPagamento: item.statusPagamento,
              historicoLancamentoDescricao: item.historicoLancamentoDescricao,
              id: item.id,
              juros: item.juros,
              numeroConta: item.numeroConta,
              tipoFormaPagamento: item.tipoFormaPagamento,
              total: item.total,
              valor: item.valor,
              valorDemonstrativo: item.valor - item.desconto,
            };
          });

          this.titulos = acresentandoValorDemonstrativo;
          this.titulosSelecionados = acresentandoValorDemonstrativo;
          this.preencheCampos();
          this.calcularSomaTotalDeBaixas();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    retornaSelecao(valor) {
      const teste = this.titulosSelecionados.includes(valor.item);

      if (teste === true) {
        this.titulosSelecionados = this.titulosSelecionados.filter(
          ({ id }) => id !== valor.item.id
        );
      } else if (teste === false) {
        this.titulosSelecionados.push(valor.item);
      }

      this.ativo = true;
    },
    atribuirJuros(data) {
      data.forEach((itemSelecionado) => {
        this.titulos.forEach((itemTitulo) => {
          if (itemTitulo.id == itemSelecionado.id) {
            itemTitulo.juros = helpers.formatacaoCasaDecimal(
              itemSelecionado.juros
            );
            itemTitulo.valorDemonstrativo =
              itemTitulo.valor -
              itemTitulo.desconto +
              Number(helpers.formatacaoCasaDecimal(itemSelecionado.juros));
          }
        });
      });
      this.calcularSomaTotalDeBaixas();
    },
    calcularJuros() {
      const id = this.ativo ? this.titulosSelecionados : this.titulos;

      const teste = id.map((item) => {
        return {
          ...item,
          dataPagamento: this.form.dataPagamento,
        };
      });

      this.$store.dispatch(START_LOADING);
      ContaReceberService.calcularJuros(teste)
        .then((data) => {
          this.atribuirJuros(data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    listarTipoFormaPagamento() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-formas-pagamento')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.listaTipoFormaPagamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    modificarFormBaseadoEmLista() {
      const resultado = this.ativo ? this.titulosSelecionados : this.titulos;
      const formLista = resultado.map((item) => {
        return {
          ...item,
          dataPagamento: this.form.dataPagamento,
          tipoFormaPagamento: this.form.tipoFormaPagamento,
          contaId: this.form.contaId,
        };
      });
      return formLista;
    },
    validarFormulario() {
      var formValido = true;
      // checa se foi selecionado tipoPagamento:
      if (!this.$refs.tipoPagamento.content) {
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('FINANCEIRO_CONTAS_RECEBER.SELECIONE_TIPO_PAGAMENTO')
        );
      }
      // checa se foi selecionado uma conta:
      if (!this.form.contaId) {
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('FINANCEIRO_CONTAS_RECEBER.ADICIONE_UMA_CONTA')
        );
      }

      this.titulosSelecionados.forEach((element) => {
        var totalParcial =
          parseFloat(element.valor) + parseFloat(element.juros);
        if (element.desconto > totalParcial) {
          formValido = false;
          return mensagem.erro(
            this.$t('Erro'),
            this.$t('Existem Descontos maiores que os valores de documentos')
          );
        }
      });

      // apto para salvar:
      if (formValido) {
        this.baixarTitulo();
      }
    },
    baixarTitulo() {
      const resultadoFormulario = this.modificarFormBaseadoEmLista();
      this.form.desconto = Number(this.form.desconto);

      // se for lista, checa se está vazio:
      if (!resultadoFormulario.length) {
        mensagem.erro('Selecione pelo menos um título');
      }

      this.$store.dispatch(START_LOADING);

      ContaReceberService.baixarTitulo(resultadoFormulario, this.ehLista)
        .then(({ data }) => {
          this.salvo = true;
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULO_SUCESSO')
          );

          const confirmacao = mensagem.confirmacao(
            null,
            'Deseja realizar a impressão do recibo ?'
          );

          Promise.resolve(confirmacao).then((confirmacao) => {
            var idsTitulos = [];
            data.data.forEach((element) => {
              idsTitulos.push(element.id);
            });
            if (confirmacao.isConfirmed) {
              this.imprimir({ ids: idsTitulos });
            }
          });

          this.$router.push({ name: 'financeiro-contas-a-receber' });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    descontoSoma(tipo) {
      const descontoSoma = this.descontos.reduce((acc, item) => {
        return (acc += item[tipo]);
      }, 0);

      return descontoSoma;
    },
    calcularDesconto() {
      const descontoSomaPercentual = this.descontoSoma('percentual');

      const descontoSomaValor = this.descontoSoma('valor');

      const constanteDeDivisao = 100;

      const descontoRealizado =
        (descontoSomaPercentual / constanteDeDivisao) *
        this.form.valorDemonstrativo;

      this.form.desconto = helpers.formatacaoCasaDecimal(
        descontoRealizado + descontoSomaValor
      );

      this.form.total = helpers.formatacaoCasaDecimal(
        this.form.valorDemonstrativo - descontoRealizado - descontoSomaValor
      );
      this.totalAux = helpers.formatacaoCasaDecimal(
        this.form.valorDemonstrativo - descontoRealizado - descontoSomaValor
      );
    },
    async atualizarDesconto(form) {
      const sucesso = await ContaReceberService.atualizarDesconto(form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PRECOS.DESCONTO_SUCESSO')
          );
          return true;
        })
        .catch((err) => {
          mensagem.showErrors(err);

          return false;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
      return sucesso;
    },
    async selecionaDesconto(descontosSelecionados) {
      const descontosId = descontosSelecionados.map(({ id }) => id);
      const id = this.titulos[0].id;

      // TODO: erro ao atualizarDesconto (retornando campo obrigatório, ñ sei qual)
      const sucesso = await this.atualizarDesconto({
        descontosId: descontosId,
        id: id,
      });
      if (sucesso) {
        this.descontos.push(...descontosSelecionados);
      }
      this.calcularDesconto();
    },
    preencheCampos() {
      let dataAtual = helpers.formatarDataEn(new Date());
      dataAtual = helpers.adicionarTempoEmData(dataAtual);

      this.titulos.forEach(
        ({ valor, total, dataVencimento, descontos, id, dataLancamento }) => {
          this.form.valor = Number(helpers.formatacaoCasaDecimal(valor));
          this.form.total = total;
          this.form.dataPagamento = dataAtual;
          this.form.dataLancamento = dataLancamento;
          this.form.dataVencimento = dataVencimento;
          this.form.id = id;
          this.form.valorDemonstrativo = helpers.formatacaoCasaDecimal(valor);
          this.descontos = descontos;
        }
      );

      this.calcularDesconto();
    },
    removerDescontoBanco(form) {
      ContaReceberService.removerDesconto(form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PRECOS.DESCONTO_SUCESSO')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async excluirDesconto(descontoExcluir) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        this.$t('PESSOAS.CONFIRMAR_EXCLUIR_CONTATO')
      );
      if (respostaExclusao.isConfirmed) {
        this.descontos.splice(descontoExcluir.index, 1);
        const descontosId = descontoExcluir.item.id;

        const id = this.titulos[0].id;
        let novoForm = { descontosId: [descontosId], id: id };

        this.removerDescontoBanco(novoForm);

        this.calcularDesconto();
      }
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
    // FUNÇÕES AUXILIARES DE COMPONENTES DE SELEÇÃO:
    obterSelecionado(tipo, item) {
      switch (tipo) {
        case 'conta':
          this.form.contaId = item.id;
          this.form.banco = item.banco;
          this.form.numeroConta = item.numeroConta;
          break;
        default:
          break;
      }
    },
    // FUNÇÕES AUXILIARES DE BAIXAR PDF:
    imprimir(id) {
      ContaReceberService.imprimirRecibo(id)
        .then(({ data }) => {
          if (data.documento) {
            //converte os bytes em array:
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            // converte o array em blob
            let blob = new Blob([bytes], { type: 'application/pdf' });

            // faz download do PDF:
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
