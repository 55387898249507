<template>
  <div class="mt-2">
    <b-table
      :items="ordenacaoPorValidade"
      :fields="campos"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      show-empty
      responsive
      striped
      hover
    >
      <template #cell(desconto)="item">
        <div class="flex">
          <input-number
            :ref="`in_${item.item.id}`"
            :value="item.item.desconto"
            @change="editaDescontoAtualizaValorTotal(item, $event)"
            decimal
          />
        </div>
      </template>
      <template #cell(juros)="item">
        <div class="flex">
          <input-number
            :value="item.item.juros"
            @change="editaJurosAtualizaValorTotal(item, $event)"
            decimal
          />
        </div>
      </template>

      <template #cell(acoes)="item">
        <div class="flex">
          <input-checkbox :value="true" @change="selecaoDeLista(item)" />
        </div>
      </template>
    </b-table>
    <paginacao :total="total" v-model="paginacao" />

    <b-row class="valor-total mx-1">
      <b-col md="3">
        <p>
          Valor total documento: {{ totalizadores.somaTotalValorDocBaixas }}
        </p>
      </b-col>
      <b-col md="3">
        <p>Valor total desconto: {{ totalizadores.somaTotalDescontoBaixas }}</p>
      </b-col>
      <b-col md="3">
        <p class="text-left text-md-right">
          Valor total juros: {{ totalizadores.somaTotalJurosBaixas }}
        </p>
      </b-col>
      <b-col md="3">
        <p class="text-left text-md-right">
          Valor total: {{ totalizadores.somaTotalBaixas }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Paginacao from '@/components/paginacao/Paginacao';
import helpers from '@/common/utils/helpers';
import { InputCheckbox, InputNumber } from '@/components/inputs';
import mensagem from '@/common/utils/mensagem';

export default {
  props: {
    form: { type: [Object, Array], default: Object },
    totalizadores: { type: Object },
  },
  components: { Paginacao, InputCheckbox, InputNumber },
  computed: {
    ordenacaoPorValidade() {
      return this.form;
    },
  },
  data() {
    return {
      campos: [
        {
          key: 'dataVencimento',
          label: this.$t('FINANCEIRO_CONTAS_RECEBER.DATA_VENCIMENTO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'statusPagamento',
          label: this.$t(`FINANCEIRO_CONTAS_RECEBER.STATUS_PAGAMENTO`),
          sortable: true,
        },
        {
          key: 'codigoMatricula',
          label: this.$t('FINANCEIRO_CONTAS_RECEBER.MATRICULA'),
          sortable: true,
        },
        {
          key: 'historicoLancamentoDescricao',
          label: this.$t(`FINANCEIRO_CONTAS_RECEBER.HISTORICO`),
          sortable: true,
        },
        {
          key: 'valor',
          label: this.$t('FINANCEIRO_CONTAS_RECEBER.VALOR_DOCUMENTO'),
          formatter: (value) => {
            return value ? helpers.formatacaoMoedaBRL(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'juros',
          label: this.$t(`FINANCEIRO_CONTAS_RECEBER.JUROS`),
        },
        {
          key: 'desconto',
          label: this.$t(`FINANCEIRO_CONTAS_RECEBER.DESCONTO`),
        },

        {
          key: 'valorDemonstrativo',
          label: 'Total',
          formatter: (value) => {
            return value ? helpers.formatacaoMoedaBRL(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('FINANCEIRO_CONTAS_RECEBER.ACOES'),
        },
      ],
      items: [],
      itensSelecionados: [],
      itensSelecionadosLista: false,
      total: 0,
      paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 10 },
    };
  },
  mounted() {
    this.itensSelecionados = this.form;
  },
  methods: {
    selecaoDeLista(valor) {
      this.$emit('retornaSelecao', valor);
    },
    editaDescontoAtualizaValorTotal(item, valor_input) {
      this.$refs['in_' + item.item.id].$el.style.border = '';

      item.item.desconto = parseFloat(valor_input);
      if (valor_input == '') {
        item.item.desconto = 0;
      }
      item.item.total =
        parseFloat(item.item.valor) +
        parseFloat(item.item.juros) -
        parseFloat(item.item.desconto);
      item.item.valorDemonstrativo = helpers.formatacaoMoedaBRL(
        item.item.total
      );
      this.atualizaValoresTotais();

      var totalParcial =
        parseFloat(item.item.valor) + parseFloat(item.item.juros);
      if (valor_input > totalParcial) {
        this.$refs['in_' + item.item.id].$el.style.border = '1px solid red';
        return mensagem.warning(
          this.$t('Aviso'),
          this.$t(
            "O valor preenchido em 'Desconto' é maior que a soma entre Valor do documento e Juros"
          )
        );
      }
    },
    editaJurosAtualizaValorTotal(item, valor_input) {
      this.$refs['in_' + item.item.id].$el.style.border = '';

      item.item.juros = parseFloat(valor_input);
      if (valor_input == '') {
        item.item.juros = 0;
      }

      item.item.total =
        parseFloat(item.item.valor) +
        parseFloat(item.item.juros) -
        parseFloat(item.item.desconto);
      item.item.valorDemonstrativo = helpers.formatacaoMoedaBRL(
        item.item.total
      );
      this.atualizaValoresTotais();

      var totalParcial =
        parseFloat(item.item.valor) + parseFloat(item.item.juros);
      if (item.item.desconto > totalParcial) {
        this.$refs['in_' + item.item.id].$el.style.border = '1px solid red';

        return mensagem.erro(
          this.$t('Aviso'),
          this.$t(
            "O valor de 'Desconto' é maior que a soma entre Valor do documento e Juros"
          )
        );
      }
    },
    atualizaValoresTotais() {
      var valores = 0,
        descontos = 0,
        juros = 0,
        totais = 0;
      this.ordenacaoPorValidade.forEach((element) => {
        valores += parseFloat(element.valor);
        descontos += parseFloat(element.desconto);
        juros += parseFloat(element.juros);
      });

      totais = parseFloat(valores) + parseFloat(juros) - parseFloat(descontos);
      this.totalizadores.somaTotalValorDocBaixas =
        helpers.formatacaoMoedaBRL(valores);
      this.totalizadores.somaTotalDescontoBaixas =
        helpers.formatacaoMoedaBRL(descontos);
      this.totalizadores.somaTotalJurosBaixas =
        helpers.formatacaoMoedaBRL(juros);
      this.totalizadores.somaTotalBaixas = helpers.formatacaoMoedaBRL(totais);
    },
  },
};
</script>

<style>
.valor-total {
  font-size: 16px;
  background: #f2f4f7;
  padding: 10px 0;
}

.valor-total p {
  margin: 0;
}
</style>
