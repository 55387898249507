<template>
  <modal
    id="ModalSelecionarDesconto"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="lg"
    :scrollable="true"
    :ocultarRodape="true"
    validarPreenchimento
    @fechar="fecharModal"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <tabela-selecionar-desconto
            @fechar="fecharModal"
            :descontos="descontos"
            :descontoSelecionado="descontoSelecionado"
            @selecionaDesconto="selecionaDesconto"
          />
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service.js';

// Components:
import Modal from '@/components/modal/Modal.vue';
import TabelaSelecionarDesconto from './Tabela';

export default {
  components: {
    Modal,
    TabelaSelecionarDesconto,
  },
  props: {
    exibir: { type: Boolean, default: false },
    item: { type: Object, default: null },
    descontoSelecionado: { type: Object, default: Object },
    descontosFiltro: { type: Array },
  },
  data() {
    return {
      descontos: [],
    };
  },
  watch: {
    descontosFiltro: 'listarDescontos',
  },
  computed: {
    titulo() {
      return !this.item
        ? this.$t('FINANCEIRO_CONTAS_RECEBER.DESCONTO')
        : this.$t('GERAL.EDITAR');
    },
  },
  methods: {
    filtrarDescontos(data, descontosTabela) {
      let descontosFiltrados = [];
      data.forEach((item, index) => {
        let resultado = [];

        descontosTabela.forEach((item2) => {
          if (item2.id === item.id) {
            resultado.push({ ...item, index });
          }
        });

        if (index !== resultado[0]?.index) {
          descontosFiltrados.push(item);
        }
      });

      return descontosFiltrados;
    },
    listarDescontos(descontosTabela) {
      this.$store.dispatch(START_LOADING);
      ContaReceberService.buscarDesconto(this.paginacao)
        .then((data) => {
          // TODO: precisa de paginação aqui :<
          this.descontos = this.filtrarDescontos(data, descontosTabela);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    selecionaDesconto(descontosSelecionados) {
      this.$emit('selecionaDesconto', descontosSelecionados);
    },
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
