<template>
  <modal
    id="modalSelecionarConta"
    titulo="FINANCEIRO.SELECIONAR_CONTA"
    :exibir="exibir"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <tabela-conta @selecionado="armazenarSelecionado" />
    <template #modal-footer>
      <b-button variant="secondary" @click="fecharModal">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
    </template>
  </modal>
</template>
<script>
// Components:
import TabelaConta from './Tabela.vue';
import Modal from '@/components/modal/Modal';

export default {
  props: {
    exibir: { type: Boolean, default: false },
  },
  components: {
    Modal,
    TabelaConta,
  },
  methods: {
    // FUNÇÕES DE EMISSÃO DE DADOS:
    armazenarSelecionado(item) {
      this.$emit('selecionado', 'conta', item);
      this.fecharModal();
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'conta');
    },
  },
};
</script>
