<template>
  <div>
    <b-table
      :items="descontos"
      :fields="campos"
      select-mode="multi"
      responsive="sm"
      ref="selectableTable"
      selectable
      @row-selected="onRowSelected"
      sticky-header
    >
    </b-table>

    <div class="text-right">
      <b-button variant="secondary" @click="fecharModal">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
      <b-button class="ml-2" variant="primary" @click="selecionaDesconto">
        {{ $t('GERAL.SELECIONE') }}
      </b-button>
    </div>
  </div>
</template>

<script>
// Utils & Aux:
import { mapState } from 'vuex';
import helpers from '@/common/utils/helpers';

export default {
  props: {
    descontos: { type: [Array, Object], default: Object },
    descontoSelecionado: { type: Object, default: Object },
  },
  computed: { ...mapState(['baixartitulo']) },
  data() {
    return {
      campos: [
        {
          key: 'descricao',
          label: 'Desconto',
        },
        {
          key: 'percentual',
          label: 'Valor (%)',
          formatter: (value) => {
            if (value) return helpers.formatacaoCasaDecimal(value);
          },
        },
        {
          key: 'valor',
          label: 'Valor (R$)',
          formatter: (value) => {
            if (value) return helpers.formatacaoCasaDecimal(value);
          },
        },
        {
          key: 'tipoDescontoDescricao',
          label: 'Tipo',
        },
      ],
      lancamentoRecebidos: [],
      descontosSelecionados: [],
    };
  },
  methods: {
    onRowSelected(items) {
      this.descontosSelecionados = items;
      this.selected = items[0];
    },
    selecionaDesconto() {
      this.descontoSelecionado.desconto = helpers.formatacaoCasaDecimal(
        this.selected.valor
      );
      this.descontoSelecionado.descricao = this.selected.descricao;
      this.descontoSelecionado.percentual = this.selected.percentual;

      this.fecharModal();
      this.$emit('selecionaDesconto', this.descontosSelecionados);
    },
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>

<style>
.table-container {
  height: 400px;
  overflow-y: scroll;
}
</style>
