<template>
  <div>
    <input-btn
      ref="contaId"
      :prepend="prependContent"
      v-model="selecionado"
      placeholder="FINANCEIRO.SELECIONAR_CONTA"
      btnAppend="FINANCEIRO.SELECIONAR_CONTA"
      @btn-emit="openModal('conta')"
      :required="required"
    />

    <modal-conta
      :exibir="modais.conta"
      @selecionado="armazenarSelecionado"
      @fechar="closeModal"
    />
  </div>
</template>
<script>
// Utils & Aux:
import modais from '@/common/utils/modais';

// Components:
import { InputBtn } from '@/components/inputs';
import ModalConta from './modal/Index';

export default {
  props: {
    required: { type: Boolean, default: false },
    limpar: { type: Boolean, default: false },
  },
  components: {
    InputBtn,
    ModalConta,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
  watch: {
    limpar() {
      this.selecionado = '';
    },
  },
  data() {
    return {
      selecionado: '',
      modais: {
        conta: false,
      },
    };
  },
  computed: {
    prependContent() {
      const prependText = this.$t('FINANCEIRO.CONTA');
      const requiredAsterisk = this.required
        ? '<span class="required-field">*</span>'
        : '';
      return `${prependText}${requiredAsterisk}`;
    },
  },
  methods: {
    // FUNÇÕES DE EMISSÃO DE DADOS:
    armazenarSelecionado(tipo, item) {
      // this.selecionado = item.bancoDescricao
      //   ? `${item.bancoDescricao} | ag: ${item.agencia} | nº: ${item.numeroConta}`
      //   : `${item.descricao}`;
      this.selecionado = item.descricao;

      this.$emit('selecionado', tipo, item);
    },
    iniciaSelecionado(descricaoContaId) {
      this.selecionado = descricaoContaId;
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
<style>
.required-field {
  color: red;
  margin-right: 4px;
}
</style>
